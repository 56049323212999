<script setup>
  import VerticalCard from '@/views/blog/components/VerticalCard.vue'
  import HorizontalCard from '@/views/blog/components/HorizontalCard.vue'
  import { computed, defineProps } from 'vue'

  const props = defineProps({
    featuredBlog: {
      type: Object,
      required: true,
      default: () => ({
        image: '',
        title: '',
        description: '',
        tags: [],
        slug: ''
      })
    },
    recentBlogs: {
      type: Array,
      required: true,
      default: () => [
        {
          image: '',
          title: '',
          description: '',
          tags: [],
          slug: ''
        }
      ]
    }
  })

  const featuredBlogData = computed(() => props.featuredBlog)
  const recentBlogData = computed(() => props.recentBlogs)
</script>

<template>
  <el-row :gutter="16" class="p-0" v-if="featuredBlogData">
    <el-col
    :lg="{ span: 15  }"
    :md="{ span: 16 }"
    :sm="{ span: 24 }"
    :xs="{ span: 24 }">
      <div class="grid-content ep-bg-purple">
        <h2 class="blog-heading">Featured Blog</h2>
        <vertical-card
            :key="featuredBlogData.slug"
            :image="featuredBlogData.banner"
            :title="featuredBlogData.title"
            :description="featuredBlogData.short_description"
            :tags="featuredBlogData.tags"
            :slug="featuredBlogData.slug"
            :readTime="featuredBlogData.readTime"
            :showTags="true" />
      </div>
    </el-col>
    <el-col :lg="{ span: 9 }"
            :md="{ span: 8 }"
            :sm="{ span: 24 }"
            :xs="{ span: 24 }">
      <h2 class="blog-heading">Recent Blog</h2>
      <div class="grid-content ep-bg-purple" >
        <horizontal-card
            v-for="blog in recentBlogData"
            :key="blog.slug"
            :image="blog.thumbnail"
            :title="blog.title"
            :description="blog.short_description"
            :readTime="blog.readTime"
            :slug="blog.slug">
        </horizontal-card>
      </div>
    </el-col>
  </el-row>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  font-weight: bold;
}
.blog-heading{
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #000000;
}

</style>
