<script setup>
  import Page from '@/components/Page.vue'
  import SimpleBreadCrumb from '@/components/SimpleBreadCrumb.vue'
  import FeaturedSection from '@/views/blog/components/FeaturedSection.vue'
  import BlogList from '@/views/blog/components/BlogList.vue'
  import { onMounted, reactive } from 'vue'
  import php from '@/php'
  import Pagination from '@/components/Pagination.vue'
  import JLTools from '@/util/JLTools'
  import PageLoader from '@/views/blog/components/PageLoader.vue'

  const data = reactive({
    blogs: [],
    featuredBlog: {},
    recentBlogs: [],
    count: 0,
    total: 0,
    totalPages: 0,
    currentPage: 1,
    loading: true
  })

  const getBlogs = () => {
    data.loading = true
    php
      .post(`services/public/blogs?page=${data.currentPage}`, [])
      .then((response) => {
        console.log('response:', response.data.data)
        data.blogs = response.data.data?.blogs
        data.count = response.data.data.count
        data.total = response.data.data.total
        data.currentPage = response.data.data?.currentPage
        data.totalPages = response.data.data?.totalPages
        data.loading = false
      })
      .catch((error) => {
        console.log('error:', error)
      })
  }
  const getFeaturedAndRecentBlog = () => {
    php
      .post(`services/public/blogs/recent-and-feature`, [])
      .then((response) => {
        console.log('feature and recent response:', response.data.data)
        data.featuredBlog = response.data.data.featuredBlog
        data.recentBlogs = response.data.data.recentBlogs
      })
      .catch((error) => {
        console.log('error:', error)
      })
  }

  onMounted(() => {
    getBlogs()
    getFeaturedAndRecentBlog()
  })

  const handCurrenPage = (val) => {
    data.currentPage = val
    getBlogs()
    JLTools.scrollTo(100)
  }
</script>

<template>
  <div>
    <PageLoader v-if="data.loading"></PageLoader>
    <Page v-else class="page-box">
      <template v-if="data.blogs.length > 0">
        <SimpleBreadCrumb :firstRoute="{ name: 'Blog', path: '/blogs' }"></SimpleBreadCrumb>
        <FeaturedSection :featuredBlog="data.featuredBlog" :recent-blogs="data.recentBlogs"></FeaturedSection>
        <hr v-if="data.featuredBlog" class="custom-hr" />
        <BlogList :blogs="data.blogs"></BlogList>
        <div class="rowCC mt20 w12">
          <Pagination v-if="data.totalPages > 0" :config="{ total: data.total, pageSize: 12, currentPage: data.currentPage }" @currentPage="handCurrenPage"></Pagination>
        </div>
      </template>
      <template v-else>
        <el-empty
            :image="require('@/assets/imgs/blog/EmptyState.svg')"
            :description="$t('We are sorry, We currently have no blog posts available. Please check back later for new content!')"
            image-size="450"
        />
      </template>
    </Page>
  </div>
</template>

<style lang="less">
  //.page-box {
  //  width: 1200px; /* Default width */
  //}
  //
  ///* Small devices (tablets, 600px and up) */
  //@media (min-width: 600px) {
  //  .page-box {
  //    width: 90%;
  //  }
  //}
  //
  ///* Medium devices (small laptops, 768px and up) */
  //@media (min-width: 768px) {
  //  .page-box {
  //    width: 80%;
  //  }
  //}
  //
  .custom-hr {
    border: none;
    height: 2px;
    background: #DCDFE6;
  }
</style>
